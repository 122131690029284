import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Stage } from '../../../projects/upload-components/src/lib/stage/stage';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, ICustomProperties, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';
import { EnvironmentInfoService } from '../../../projects/environment-info/src/lib/environment-info.service';
import { AuftragStatus } from '../enums/auftrag.enums';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { DOCUMENT } from '@angular/common';

export enum Benutzer {
  Mitbenutzer = 'Mitbenutzer',
  Vertreter = 'Vertreter',
  Assistent = 'Assistent',
  Hauptnutzer = 'Hauptnutzer'
}

/**
 * Custom Error class for Application Insights
 * @param message - Error message
 * @param details - Additional details like device information, which will be added to the customDimensions or customMeasurements of the event
 *
 * @example
 * throw new CustomAppInsightsError('My custom error message', { device: 'Smartphone', hostname: window.location.hostname })
 */
export class CustomAppInsightsError extends Error {
  constructor(
    message: string,
    public details?: any
  ) {
    super(message);
    this.name = 'CustomAppInsightsError';
  }
}

export enum UploadDialogType {
  File = 'file',
  Camera = 'camera',
  Tool = 'tool'
}

export enum DT_FE_CUSTOM_EVENT {
  APP_GESTARTET = 'DT_FE_APP_GESTARTET',
  UMFRAGE_GESTARTET = 'DT_FE_UMFRAGE_GESTARTET',
  DASHBOARD_LINK_GEOEFFNET = 'DT_FE_DASHBOARD_LINK_AUFGERUFEN', // Datenschutz, Impressum
  DASHBOARD_SORTIERUNGSWECHSEL = 'DT_FE_DASHBOARD_SORTIERUNGSWECHSEL',
  DASHBOARD_TABWECHSEL = 'DT_FE_DASHBOARD_TABWECHSEL', // Tab Offene / Bearbeitet
  DOKUMENTENVIEWER_ACTION = 'DT_FE_DOKUMENTENVIEWER_ACTION', // zoom, Auf_Breite_anpasssen, ganzes_Dokument_anzeigen, Lösche_Auftrag, Download, nächste_Seite, vorherige_Seite
  KUNDENZUORDNUNG_GEAENDERT = 'DT_FE_KUNDENZUORDNUNG_GEAENDERT',
  CLICKANDCOLLECT = 'DT_FE_CLICKANDCOLLECT',
  LEISTUNGSRADAR_GEOEFFNET = 'DT_FE_LEISTUNGSRADAR_GEOEFFNET',
  ANGABEN_UEBERNOMMEN = 'DT_FE_ANGABEN_UEBERNOMMEN',
  // Upload-Dialog Events
  UPLOAD_DIALOG_GEOEFFNET = 'DT_FE_UPLOAD_DIALOG_GEOEFFNET',
  UPLOAD_VARIANTE = 'DT_FE_UPLOAD_VARIANTE',
  UPLOAD_DATEIAUSWAHL_FEHLER = 'DT_FE_DATEIAUSWAHL_FEHLER ',
  IMAGE_CROPPED = 'DT_FE_IMAGE_CROPPED',
  QR_UPLOAD_GESTARTET = 'DT_FE_APP_QR_UPLOAD_GESTARTET',
  UPLOADED_DOCUMENT = 'DT_FE_DOKUMENT_HOCHGELADEN', // Wird für jedes Dokument eines Buckets getriggert
  UPLOADER_ENDPOINT = 'DT_FE_DOKUMENTEN_UPLOAD_ENDPUNKT',
  UPLOADER_AUFTRAG = 'DT_FE_DOKUMENTEN_UPLOAD_AUFTRAG',
  UPLOAD_ABGESCHLOSSEN = 'DT_FE_UPLOAD_ABGESCHLOSSEN',
  FEHLER_AUFGETRETEN = 'DT_FE_FEHLER_AUFGETRETEN'
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private connString: string;
  appInsights: ApplicationInsights;

  private angularPlugin = new AngularPlugin();
  private clickPluginInstance = new ClickAnalyticsPlugin();
  private clickPluginConfig = {
    autoCapture: true,
    dataTags: {
      useDefaultContentNameOrId: true
    }
  };

  private uploadVariante: string;
  private benutzertyp: string;
  private deviceCustomDimensions: ICustomProperties;

  constructor(
    private stage: Stage,
    private router: Router,
    private environmentInfoService: EnvironmentInfoService,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.log('ApplicationInsightsService created');
  }

  init() {
    try {
      this.initApplicationInsights();
      this.deviceCustomDimensions = {
        geraetekategorie: this.checkDevice(),
        useragent: this.environmentInfoService.getUseragent(),
        maxTouchPoints: this.environmentInfoService.getMaxTouchPoints()
      };
      console.log('Init ApplicationInsights');
    } catch (e) {
      console.log('Init ApplicationInsights  failed', e);
    }
  }

  private initApplicationInsights() {
    const stage = this.stage.getStage();
    switch (stage) {
      case 'localhost':
      case 'entwicklung':
        this.connString =
          'InstrumentationKey=587f938c-3eba-465e-aeda-443bbfda9186;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=9fc5609b-14c0-4eac-b87a-fcf3611abd4f';
        break;
      case 'digi.local':
      case 'integration':
        this.connString =
          'InstrumentationKey=15b11aba-c0d6-4672-82c3-7638b855f54d;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=33818d1e-5a5d-4a1f-94bc-fd91ff1cc312';
        break;
      case 'abnahme':
        this.connString =
          'InstrumentationKey=5f6d3767-8911-4950-bab3-5cbdff1097d7;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=b5218d0c-bf90-423b-9202-6e8792699996';
        break;
      case 'produktion':
        this.connString =
          'InstrumentationKey=d926da89-5dfb-479e-b8ad-ecc3223058c0;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=c4aad66d-dedb-46b5-9e24-b79827e13228';
        break;
      default:
        this.connString =
          'InstrumentationKey=587f938c-3eba-465e-aeda-443bbfda9186;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=9fc5609b-14c0-4eac-b87a-fcf3611abd4f';
    }

    this.appInsights = new ApplicationInsights({
      config: {
        enableAutoRouteTracking: true,
        maxBatchInterval: 0,
        connectionString: this.connString,
        disableCookiesUsage: true,
        extensions: [this.angularPlugin, this.clickPluginInstance],
        extensionConfig: {
          [this.angularPlugin.identifier]: {
            router: this.router,
            errorServices: stage === 'produktion' ? [] : [new ErrorHandler()] // Damit die Errors weiterhin auf der Konsole geloggt werden außer in Produktion.
          },
          [this.clickPluginInstance.identifier]: this.clickPluginConfig,
          ['AppInsightsCfgSyncPlugin']: {
            cfgUrl: '' // this will block fetching from default cdn
          }
        }
      }
    });

    this.appInsights.loadAppInsights();

    this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      let itemTags = envelope.tags;
      if (itemTags) {
        itemTags = itemTags || [];
        itemTags['ai.cloud.role'] = 'datacollect.datacollect-frontend.APP-00125';
      }
      return true;
    });
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    let customProps: ICustomProperties = {
      ...this.deviceCustomDimensions,
      benutzertyp: this.benutzertyp
    };
    if (properties) {
      customProps = { ...customProps, ...properties };
    }

    this.appInsights?.trackEvent({ name: name }, customProps);
  }

  private checkDevice(): string {
    if (this.environmentInfoService.checkDeviceIsSmartphone()) {
      return 'Smartphone';
    } else if (this.environmentInfoService.checkDeviceIsTablet()) {
      return 'Tablet';
    } else if (this.environmentInfoService.checkDeviceIsWindowsSurface()) {
      return 'WindowsSurface';
    } else {
      return 'Desktop';
    }
  }

  logAppGestartetAlsBenutzertyp(benutzer?: string, hasContextUser: boolean = false) {
    console.log(benutzer ?? 'Kein Benutzer gesetzt');
    if (!benutzer) {
      this.logError('Benutzer unbekannt');
      return;
    }

    //Mitbenutzer endet mit Zahl > 0
    const isMitbenutzer = !!benutzer.match(/^[0-9]*[1-9]$/);

    // TODO: Vertreter werden nicht erkannt
    if (benutzer.startsWith('0') || benutzer === Benutzer.Assistent) {
      this.benutzertyp = Benutzer.Assistent;
    } else if (isMitbenutzer) {
      this.benutzertyp = Benutzer.Mitbenutzer;
    } else if (benutzer === Benutzer.Vertreter || hasContextUser) {
      this.benutzertyp = Benutzer.Vertreter;
    } else {
      this.benutzertyp = Benutzer.Hauptnutzer;
    }

    this.logEvent(DT_FE_CUSTOM_EVENT.APP_GESTARTET);
  }

  logUploadVarianteSelected(uploadVariante: string) {
    this.uploadVariante = uploadVariante;
    this.logEvent(DT_FE_CUSTOM_EVENT.UPLOAD_VARIANTE, {
      variante: uploadVariante
    });
  }

  //Datenturbo, MeineApp, MeineKunden etc.
  logUploadAbgeschlossen(ursprung: string, formulartyp: string) {
    this.logEvent(DT_FE_CUSTOM_EVENT.UPLOAD_ABGESCHLOSSEN, {
      ursprung: ursprung,
      formulartyp: formulartyp,
      variante: this.uploadVariante
    });
  }

  logKundenzuordnungChanged() {
    this.logEvent(DT_FE_CUSTOM_EVENT.KUNDENZUORDNUNG_GEAENDERT);
  }

  logAngabenUbernommen(auftragBearbeitungsStatus: AuftragStatus, anzahlVertraege: number) {
    this.logEvent(DT_FE_CUSTOM_EVENT.ANGABEN_UEBERNOMMEN, {
      erfassungsverteilung: auftragBearbeitungsStatus,
      anzahlVertraege: anzahlVertraege.toString()
    });
  }

  logError(ausloeser: string, error?: Error | CustomAppInsightsError) {
    let errorDetails = {};
    if (error) {
      errorDetails = { errormessage: error.message };
      errorDetails = { ...errorDetails, ...(error instanceof CustomAppInsightsError ? error.details : {}) };
      this.appInsights?.trackException(
        { exception: error },
        {
          ausloeser: ausloeser,
          ...errorDetails
        }
      );
    }

    this.logEvent(DT_FE_CUSTOM_EVENT.FEHLER_AUFGETRETEN, {
      ausloeser: ausloeser,
      hostname: this.document.location.hostname,
      url: this.document.location.href,
      ...errorDetails
    });
  }

  logUmfrageGestartet() {
    this.logEvent(DT_FE_CUSTOM_EVENT.UMFRAGE_GESTARTET);
  }

  logClickAndCollect() {
    this.logEvent(DT_FE_CUSTOM_EVENT.CLICKANDCOLLECT);
  }

  logDokumentenViewerActionClicked(actionName: string) {
    this.logEvent(DT_FE_CUSTOM_EVENT.DOKUMENTENVIEWER_ACTION, {
      actionname: actionName
    });
  }

  logLeistungsradarLinkClicked(bereichsId, tarifId, sparteId) {
    this.logEvent(DT_FE_CUSTOM_EVENT.LEISTUNGSRADAR_GEOEFFNET, {
      bereichsId: bereichsId,
      tarifId: tarifId,
      sparteId: sparteId
    });
  }

  logImageCropped() {
    this.logEvent(DT_FE_CUSTOM_EVENT.IMAGE_CROPPED);
  }

  logOpenedUploadDialog(dialogType: UploadDialogType) {
    this.logEvent(DT_FE_CUSTOM_EVENT.UPLOAD_DIALOG_GEOEFFNET, {
      dialogType: dialogType,
      hostname: this.document.location.hostname,
      url: this.document.location.href
    });
  }

  logDateiauswahlAlert(errorReason: string, additionalInfo: { [key: string]: any }) {
    this.logEvent(DT_FE_CUSTOM_EVENT.UPLOAD_DATEIAUSWAHL_FEHLER, {
      errorReason: errorReason,
      ...additionalInfo,
      hostname: this.document.location.hostname,
      url: this.document.location.href
    });
  }
}
