import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { KeycloakApiService } from '../store/services/keycloak.api.service';
import { environment } from '../../environments/environment';

/**
 * This interceptor includes the bearer by default in all HttpClient requests.
 *
 * If you need to exclude some URLs from adding the bearer, please, take a look
 * at the {@link KeycloakOptions} bearerExcludedUrls property.
 */
@Injectable({
  providedIn: 'root'
})
export class KeycloakBearerInterceptor implements HttpInterceptor {
  shouldIntercept = true;

  constructor(private keycloakApiService: KeycloakApiService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(req)) {
      return this.keycloakApiService.updateTokenIfNeeded().pipe(
        mergeMap(token => {
          return next.handle(req.clone({ setHeaders: { Authorization: 'bearer ' + token } }));
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private isValidRequestForInterceptor(req: HttpRequest<any>): boolean {
    if (
      !(req.url.startsWith(environment.be.baseUrl) || req.url.startsWith(environment.zob.baseUrl)) ||
      window.location.href.startsWith(environment.qrfotoupload.baseUrl)
    ) {
      return false;
    }
    return this.shouldIntercept;
  }
}
