import { Inject, Injectable, InjectionToken } from '@angular/core';

const backendUrls = {
  // Diese Konfiguration ist zu verwenden, wenn das Backend lokal gestartet wird, Damit Aufträge hochgeladen werden können
  // localhost: 'http://localhost:8080/rest/v1',
  localhost: 'https://datenturbo.entwicklung.dvag/rest/v1',
  entwicklung: 'https://datenturbo.entwicklung.dvag/rest/v1',
  integration: 'https://datenturbo.integration.dvag/rest/v1',
  abnahme: 'https://datenturbo.abnahme.dvag/rest/v1',
  produktion: 'https://datenturbo.dvag/rest/v1'
};

export const STAGE = new InjectionToken<StageName>('current stage', {
  providedIn: 'root',
  factory: () => detectStage(document.location.hostname)
});

export type StageName = 'entwicklung' | 'integration' | 'abnahme' | 'produktion' | 'localhost';

// TODO: document.location.hostname als default value mit rein geben
export function detectStage(hostname: string): StageName {
  let detectedStage;
  switch (hostname) {
    case 'datenturbo.entwicklung.dvag':
    case 'digi.entwicklung.dvag':
      detectedStage = 'entwicklung';
      break;

    case 'digi.local':
    case 'datenturbo.integration.dvag':
    case 'digi.integration.dvag':
      detectedStage = 'integration';
      break;

    case 'datenturbo.abnahme.dvag':
    case 'digi.abnahme.dvag':
      detectedStage = 'abnahme';
      break;

    case 'datenturbo.dvag':
    case 'digi.dvag':
      detectedStage = 'produktion';
      break;
    // darf nur für Testzwecke aktiviert werden, da sonst MeinPortal Probleme mit der WebKomponente in Abnahme bekommt
    //case 'localhost':
    //detectedStage = 'localhost'
    //break

    default:
      detectedStage = Array.from(document.getElementsByTagName('script'))
        .map(script => script.getAttribute('src'))
        .filter(src => src != null)
        .map(relativeSrc => new URL(relativeSrc, document.baseURI))
        .filter(src => src.pathname.includes('/elements/upload/elements.js'))
        .map(src => {
          return {
            'digi.entwicklung.dvag': 'entwicklung',
            'datenturbo.entwicklung.dvag': 'entwicklung',
            'digi.integration.dvag': 'integration',
            'datenturbo.integration.dvag': 'integration',
            'digi.abnahme.dvag': 'abnahme',
            'datenturbo.abnahme.dvag': 'abnahme',
            'digi.dvag': 'produktion',
            'datenturbo.dvag': 'produktion'
          }[src.hostname];
        })[0];
  }
  return detectedStage ?? 'entwicklung';
}

@Injectable({ providedIn: 'root' })
export class Stage {
  private stage: StageName = 'entwicklung';
  private backendUrl: string;

  constructor(@Inject(STAGE) umgebung: StageName) {
    this.updateStage(umgebung);
  }

  public updateStage(stage: StageName): void {
    this.stage = stage;
    this.backendUrl = backendUrls[stage];
  }

  public getBackendUrl(): string {
    return this.backendUrl;
  }

  public getStage(): string {
    return this.stage.toString();
  }
}
