import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { EnvironmentInfoService } from '../../../../environment-info/src/lib/environment-info.service';

export interface CameraError {
  type: 'CameraError' | 'ConstraintsError' | 'OtherError';
}
@Component({
  selector: 'lib-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
  standalone: false
})
export class CameraComponent implements OnInit, OnDestroy {
  rotationAngle = 0;

  @Input()
  images: string[];

  @Input()
  originalImages: any;

  @Output()
  openPreview: EventEmitter<any> = new EventEmitter();

  @Output()
  noCameraAvailable: EventEmitter<any> = new EventEmitter<CameraError>();

  @Output()
  extendedImages: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  extendedOriginalImages: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  noImageCapturedErrorOccured: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  isPlaying = false;
  isCapturing = false;
  debugInfo = '';

  availableCameras: MediaDeviceInfo[] = [];
  activeDeviceId: string = null;

  constraints: MediaStreamConstraints = {
    audio: false,
    video: {
      facingMode: { ideal: 'environment' },
      // 20 Megapixel https://de.wikipedia.org/wiki/Bildaufl%C3%B6sungen_in_der_Digitalfotografie
      // width: { ideal: 5963 },
      // height: { ideal: 3354 }
      // 4k
      // width: { ideal: 3840 },
      // height: { ideal: 2160 },
      // 1080p
      // width: { ideal: 1920 },
      // height: { ideal: 1080 }
      // min 1080p ideal 4k
      width: this.environmentInfoService.isDevelopmentEnv() ? { ideal: 3840 } : { min: 1920, ideal: 3840 },
      height: this.environmentInfoService.isDevelopmentEnv() ? { ideal: 2160 } : { min: 1080, ideal: 2160 },
      frameRate: { min: 10, max: 30 }
    }
  };

  mediaStream: MediaStream;
  private cameraClick = new Audio('https://datenturbo.abnahme.dvag/assets/sounds/camera.mp3');

  constructor(
    public environmentInfoService: EnvironmentInfoService,
    private renderer: Renderer2,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.cameraClick?.load();
    await this.startCamera();
    this.changeDetectionRef.detectChanges();
  }

  showPreview() {
    this.openPreview.emit(null);
  }

  async getWindowsCameraRearId(): Promise<string> {
    return (await navigator.mediaDevices.enumerateDevices())
      .filter(it => it.kind === 'videoinput')
      .find(videoDevice => videoDevice.label.includes('Microsoft Camera Rear'))?.deviceId;
  }

  private rotateVideoForWindowsSurface() {
    // KUBA-1248 fixed rotation problem on windows surface devices
    // Update: Chrome till version 87 has new WebRTC support. No rotation is needed
    if (
      this.environmentInfoService.checkDeviceIsWindowsSurface() &&
      !this.environmentInfoService.isChromeWithWebRTCSupport()
    ) {
      screen.orientation.addEventListener('change', () => {
        this.rotationAngle = screen.orientation.angle === 0 ? 0 : 360 - screen.orientation.angle;
        this.renderer.setStyle(this.videoElement.nativeElement, 'transform', 'rotate(' + this.rotationAngle + 'deg)');
        this.changeDetectionRef.detectChanges();
      });

      this.rotationAngle = screen.orientation.angle === 0 ? 0 : 360 - screen.orientation.angle;
      setTimeout(() => {
        this.renderer.setStyle(this.videoElement.nativeElement, 'transform', 'rotate(' + this.rotationAngle + 'deg)');
      }, 0);
    }
  }

  async startCamera() {
    this.rotateVideoForWindowsSurface();
    try {
      const stream = await navigator.mediaDevices.getUserMedia(this.constraints);
      await this.attachVideo(stream);
    } catch (e) {
      let error: CameraError;

      if (e.name === 'NotAllowedError') {
        error = { type: 'CameraError' };
      } else if (e.name === 'OverconstrainedError') {
        error = { type: 'ConstraintsError' };
      } else {
        error = { type: 'OtherError' };
      }

      this.noCameraAvailable.emit(error);
    } finally {
      this.changeDetectionRef.detectChanges();
    }
  }

  async setConstraintsForWindowsSurface(): Promise<MediaTrackConstraints> {
    const cameraId = await this.getWindowsCameraRearId();
    return {
      width: this.environmentInfoService.isDevelopmentEnv() ? { ideal: 3840 } : { min: 1920, ideal: 3840 },
      height: this.environmentInfoService.isDevelopmentEnv() ? { ideal: 2160 } : { min: 1080, ideal: 2160 },
      frameRate: { max: 30 },
      deviceId: cameraId
    };
  }

  async setConstraintsForiOS15(): Promise<MediaTrackConstraints> {
    return {
      facingMode: { ideal: 'environment' },
      width: { ideal: 1920 },
      height: { ideal: 1080 },
      frameRate: { max: 30 }
    };
  }

  async setNewConstraints(stream: MediaStream, newConstraints: MediaTrackConstraints): Promise<MediaStream> {
    stream.getTracks().forEach(track => {
      track.stop();
    });
    stream = await navigator.mediaDevices.getUserMedia({ video: newConstraints });
    return stream;
  }

  async attachVideo(stream: MediaStream) {
    if (this.environmentInfoService.checkDeviceIsWindowsSurface()) {
      const newConstraints = await this.setConstraintsForWindowsSurface();
      stream = await this.setNewConstraints(stream, newConstraints);
      this.changeDetectionRef.detectChanges();
    }
    // TODO: ausbauen, wenn iOS 15 funktioniert mit WebRTC
    if (this.environmentInfoService.checkiOS15Device()) {
      const newConstraints = await this.setConstraintsForiOS15();
      stream = await this.setNewConstraints(stream, newConstraints);
      this.changeDetectionRef.detectChanges();
    }

    this.mediaStream = stream;

    this.addVideoStreamDebugContainer(stream);

    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);

    this.changeDetectionRef.detectChanges();
  }

  private addVideoStreamDebugContainer(stream) {
    this.renderer.listen(this.videoElement.nativeElement, 'resize', event => {
      const video = this.videoElement.nativeElement;
      const track = stream.getVideoTracks()[0];

      if (!this.environmentInfoService.checkBrowserIsFirefox()) {
        this.activeDeviceId = track.getCapabilities().deviceId;
      } else {
        this.activeDeviceId = track.id;
      }
      const { width, height, frameRate } = track.getSettings();
      const fps = `${width}x${height}x${frameRate}`;

      this.debugInfo = `DeviceID: ${this.activeDeviceId}
Label: ${track.label}
Aufloesung: ${video.videoWidth} x ${video.videoHeight}
Maximal: ${this.environmentInfoService.checkBrowserIsFirefox() ? 'n.a.' : track?.getCapabilities().width.max} x ${this.environmentInfoService.checkBrowserIsFirefox() ? 'n.a.' : track?.getCapabilities().height.max}
Facing Mode: ${this.environmentInfoService.checkBrowserIsFirefox() ? 'n.a.' : JSON.stringify(track?.getCapabilities().facingMode)}
FPS: ${this.environmentInfoService.checkBrowserIsFirefox() ? 'n.a.' : fps}
isSurface: ${this.environmentInfoService.checkDeviceIsWindowsSurface()}
isTablet: ${this.environmentInfoService.checkDeviceIsTablet()}
screenOrientation: ${screen.orientation?.type} ${screen.orientation?.angle}`;

      this.changeDetectionRef.detectChanges();
    });
  }

  private stopCamera() {
    this.isPlaying = false;
    this.mediaStream?.getTracks().forEach(track => track.stop());
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', null);
    this.rotationAngle = 0;
  }

  playing() {
    this.isPlaying = true;
    this.changeDetectionRef.detectChanges();
  }

  capture() {
    this.cameraClick?.play().catch(e => {
      console.log(
        'CameraComponent.capture(): could not play the sound for capturing an image cause the file does not exist'
      );
    });

    this.isCapturing = true;
    this.changeDetectionRef.detectChanges();

    const video = this.videoElement.nativeElement;

    const ctx = this.getCanvasContext(this.rotationAngle, video.videoHeight, video.videoWidth);

    ctx.drawImage(video, 0, 0);

    const image = ctx.canvas.toDataURL('image/jpeg');
    if (!image) {
      console.error('Bei dem Rendern der');
      this.isCapturing = false;
      this.noImageCapturedErrorOccured.emit(true);
      this.changeDetectionRef.detectChanges();
    } else {
      this.images.push(image);
      this.originalImages.push(image);
      this.extendedImages.emit(this.images);
      this.extendedOriginalImages.emit(this.originalImages);
      this.isCapturing = false;

      this.showPreview();
      this.changeDetectionRef.detectChanges();
    }
  }

  getCanvasContext(deviceAngleDeg: number, videoHeight: number, videoWidth: number): CanvasRenderingContext2D {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', videoWidth ? videoWidth : 0);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', videoHeight ? videoHeight : 0);

    const ctx = this.canvas.nativeElement.getContext('2d');

    // on all other devices than windows surface we can use the origin context of the canvas
    if (!this.environmentInfoService.checkDeviceIsWindowsSurface()) {
      return ctx;
    }

    // on windows surfaces we hafe to rotate the canvas bevor we draw the image to the canvas
    if (Math.abs(Math.trunc(deviceAngleDeg / 90) % 2)) {
      this.renderer.setProperty(this.canvas.nativeElement, 'width', videoHeight ? videoHeight : 0);
      this.renderer.setProperty(this.canvas.nativeElement, 'height', videoWidth ? videoWidth : 0);
    }

    ctx.translate(this.canvas.nativeElement.width / 2, this.canvas.nativeElement.height / 2);
    ctx.rotate((deviceAngleDeg * Math.PI) / 180);

    if (Math.abs(Math.trunc(deviceAngleDeg / 90) % 2)) {
      ctx.translate(-(this.canvas.nativeElement.height / 2), -(this.canvas.nativeElement.width / 2));
    } else {
      ctx.translate(-(this.canvas.nativeElement.width / 2), -(this.canvas.nativeElement.height / 2));
    }
    return ctx;
  }

  ngOnDestroy(): void {
    this.stopCamera();
  }
}
