import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentInfoService {
  smartphoneRegExList = [/iPhone/, /Android.*Mobile/, /BlackBerry/, /Windows Phone/];
  tabletRegExList = [/iPad/, /Android/];
  isSmartphone = false;
  isTablet = false;

  constructor() {
    const currentUserAgent = window.navigator.userAgent;
    this.isSmartphone = this.smartphoneRegExList.some(rx => rx.test(currentUserAgent));
  }

  checkDeviceIsSmartphone(): boolean {
    return this.isSmartphone;
  }

  getUseragent(): string {
    return navigator.userAgent;
  }

  getMaxTouchPoints(): number {
    return navigator.maxTouchPoints;
  }

  checkDeviceIsTablet(): boolean {
    const currentUserAgent = navigator.userAgent;
    const iPadOS13 = !this.checkDeviceIsWindows() && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    this.isTablet = this.tabletRegExList.some(rx => rx.test(currentUserAgent)) || iPadOS13;
    return this.isTablet;
  }

  checkiOSDevice(): boolean {
    const currentUA = navigator.userAgent.toLowerCase();
    const iPadOS13 = !this.checkDeviceIsWindows() && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    const iphone = !this.checkDeviceIsWindows() && currentUA.includes('iphone');
    const ipod = currentUA.includes('ipod');
    const ipad = iPadOS13 || currentUA.includes('ipad');
    return iphone || ipod || ipad;
  }

  checkiOS15Device(): boolean {
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1"
    const matchVersion = '15';
    if (this.checkiOSDevice()) {
      const currentUA = navigator.userAgent.toLowerCase();
      const osIdx = currentUA.indexOf('os ');
      const version = currentUA.substr(osIdx + 3, 2).replace('_', '.');
      if (version === matchVersion) {
        return true;
      }
    }

    return false;
  }

  checkDeviceIsWindows() {
    const currentUserAgent = navigator.userAgent;
    return currentUserAgent.includes('Windows');
  }

  checkDeviceIsWindowsSurface() {
    const currentUserAgent = navigator.userAgent;
    return this.checkDeviceIsWindows() && navigator.maxTouchPoints > 1 && !currentUserAgent.includes('Windows Phone');
  }

  checkBrowserIsFirefox() {
    const currentUserAgent = navigator.userAgent;
    return currentUserAgent.includes('Mozilla') && currentUserAgent.includes('Firefox');
  }

  checkBrowserIsSafari() {
    const currentUserAgent = navigator.userAgent;
    return currentUserAgent.includes('Safari') && !currentUserAgent.includes('Chrome');
  }

  isDevelopmentEnv() {
    const hostName = this.getHostname();
    return ['entwicklung', 'integration', 'digi.local', 'localhost', '192.', '10.', '.deutschefin.tech'].find(elem =>
      hostName.includes(elem)
    );
  }

  getHostname(): string {
    return window.location.hostname;
  }

  // Chrome versions above 87 have new WebRTC support
  isChromeWithWebRTCSupport() {
    const splitedBrowserData = navigator.userAgent.match(/(Chrome(?=\/))\/?\s*(\d+)/i) || [];
    let retValue = false;

    if (splitedBrowserData && splitedBrowserData.length > 0) {
      if (splitedBrowserData[1] === 'Chrome') {
        const version = +splitedBrowserData[2];
        retValue = version >= 87;
      }
    }
    return retValue;
  }
}
