import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { DetectedQuadResultItem } from 'dynamsoft-document-normalizer';
import {
  ApplicationInsightsService,
  CustomAppInsightsError
} from '../../../../src/app/services/application-insights.service';
import { DxAlert } from '@dvag/design-system-angular';
import { LicenseService } from '../../../upload-components/src/lib/services/license.service';
import { Subscription } from 'rxjs';

enum ComponentType {
  FotoComponent,
  DateiAuswahl
}

@Component({
  selector: 'lib-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  standalone: false
})
export class ImageCropperComponent implements OnInit, OnDestroy {
  @ViewChild('imageCropper') imageCropper!: ElementRef;
  @ViewChild('dxAlert') dxAlert: DxAlert;

  /**
   * This flag hiddes the footer of the cropper which contains certain buttons for canceling and confirming the cropping.
   */
  @Input() shouldHideFooter? = false;
  @Output() cropperOpen = new EventEmitter<boolean>();

  @Output() cropperReady = new EventEmitter<ImageCropperComponent>();
  @Output() croppingDone: EventEmitter<string> = new EventEmitter<string>();

  license: string | null = null;
  private subscription: Subscription | null = null;
  enableDDN = true;
  status = '';
  croppedImageSrc = '';
  cropperStyle = 'none';
  isloading = false;
  public alertTitle = '';
  public alertBody = '';
  public alertIcon = '';
  public alertType = '';
  requiredMinFileSize = 70000;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private appinsightsService: ApplicationInsightsService,
    private licenseService: LicenseService
  ) {}

  ngOnInit(): void {
    this.subscription = this.licenseService.license$.subscribe(license => {
      this.license = license;
      this.changeDetectorRef.detectChanges();
      if (this.imageCropper) {
        this.imageCropper.nativeElement.addEventListener('canceled', this.canceled);
        this.imageCropper.nativeElement.addEventListener('confirmed', this.confirmed);
        this.cropperReady.emit(this);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  // Because of a Bug on Safari we have to set the width to 100% manually
  setImageWidth(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const imageCropperElement = this.imageCropper.nativeElement;
      const shadowRoot = imageCropperElement?.shadowRoot;

      if (!shadowRoot) {
        return reject('No shadow root found');
      }

      const svgContainer = shadowRoot.querySelector('svg');

      if (!svgContainer) {
        return reject('No "svg" container found');
      }

      const mutationObserver = new MutationObserver(mutations => {
        const childListMutation = mutations.find(mutation => mutation.type === 'childList');
        const imageElement = childListMutation ? svgContainer.querySelector('image') : null;

        if (imageElement) {
          imageElement.setAttribute('width', '100%');
          console.log('Image width set to 100%');
        }
      });

      mutationObserver.observe(svgContainer, {
        attributes: false,
        childList: true,
        characterData: false,
        subtree: true
      });
      resolve();
    });
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }

  async openCropper(fileBlob: File) {
    if (this.shouldHideFooter) {
      this.imageCropper.nativeElement.hidefooter = true;
    }

    if (fileBlob.size < this.requiredMinFileSize) {
      const fileIsToSmall = true;
      this.handleAlertPresentation(fileIsToSmall);
      return;
    }
    const dataUrl: string = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => {
        console.error('Error reading file');
        reject(reader.error);
      };
      reader.readAsDataURL(fileBlob);
    });
    await this.showCropper(dataUrl, ComponentType.DateiAuswahl);
  }

  async showCropper(base64Image: string, componentType: ComponentType = ComponentType.FotoComponent) {
    this.cropperOpen.emit(true);
    const cropper = this.imageCropper.nativeElement;
    cropper.img = null;

    try {
      // Ensure the image is fully loaded before assigning it to cropper.img
      const img = await this.loadImage(base64Image);
      cropper.img = img;
      await this.setupCropper(cropper, img, componentType);
    } catch (error) {
      console.error(error);
      return;
    }

    this.cropperStyle = '';
    this.changeDetectorRef.detectChanges();
  }

  async setupCropper(cropper: any, img: HTMLImageElement, componentType: ComponentType) {
    await this.setImageWidth();

    let quads: DetectedQuadResultItem[] = [];

    if (this.enableDDN) {
      this.status = 'Detecting...';
      try {
        quads = await cropper.detect(cropper.img);
        console.log(quads);
      } catch (error) {
        console.log(error);
        const customError: CustomAppInsightsError = new CustomAppInsightsError(
          'ImageCropper: error in object Detection - please check License validity',
          { hostname: window.location.hostname }
        );
        this.appinsightsService.logError('ImageCropperComponent', customError);
      }
      this.status = '';
      const widthDefault = img.naturalWidth;
      const heightDefault = img.naturalHeight;
      cropper.quad =
        quads.length > 0
          ? quads[0].location
          : {
              points: [
                { x: 0, y: 0 },
                { x: widthDefault, y: 0 },
                { x: widthDefault, y: heightDefault },
                {
                  x: 0,
                  y: heightDefault
                }
              ]
            };
    } else {
      cropper.rect = {
        x: componentType === ComponentType.FotoComponent ? 50 : 100,
        y: componentType === ComponentType.FotoComponent ? 50 : 100,
        width: img.naturalWidth - (componentType === ComponentType.FotoComponent ? 100 : 200),
        height: img.naturalHeight - (componentType === ComponentType.FotoComponent ? 100 : 200)
      };
    }
  }

  private async loadImage(imageSrc: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.src = imageSrc;

      if (img.complete) {
        resolve(img);
      } else {
        img.onload = () => resolve(img);
        img.onerror = reject;
      }
    });
  }

  canceled = () => {
    this.cropperStyle = 'none';
    this.cropperOpen.emit(false);
    this.imageCropper.nativeElement.dispose;
    this.changeDetectorRef.detectChanges();
  };

  confirmed = async () => {
    this.isloading = true;
    const cropper = this.imageCropper.nativeElement;
    try {
      this.croppedImageSrc = await cropper.getCroppedImage({ perspectiveTransform: true });
    } catch (error) {
      const cropperError = new Error('ImageCropperError - Error getting cropped image: ' + error.message);
      console.error(cropperError);
      this.handleAlertPresentation();
      this.isloading = false;
      this.cropperStyle = 'none';
      this.cropperOpen.emit(false);
      this.changeDetectorRef.detectChanges();
      this.appinsightsService.logError('ImageCropper', cropperError);
      return;
    }
    this.croppingDone.emit(this.croppedImageSrc);
    this.cropperOpen.emit(false);

    this.isloading = false;
    this.cropperStyle = 'none';

    this.changeDetectorRef.detectChanges();
    this.appinsightsService.logImageCropped();
  };

  handleAlertPresentation(fileIsToSmall?) {
    if (fileIsToSmall) {
      this.alertTitle = 'Zuschneiden nicht möglich';
      this.alertBody = `Die Bildgröße ist zu gering, um weiter zugeschnitten zu werden. Bitte verwenden Sie ein anderes Bild mit einer besseren Qualität.`;
      this.alertIcon = 'warndreieck';
      this.alertType = 'error';
    } else {
      this.alertTitle = 'Zuschneiden nicht möglich';
      this.alertBody = `Bitte versuchen Sie es erneut. Sollte das Problem weiterhin auftreten, verwenden Sie bitte ein anderes Bild.`;
      this.alertIcon = 'warndreieck';
      this.alertType = 'error';
    }
    this.dxAlert.visible = true;
    this.changeDetectorRef.detectChanges();
  }
}
