import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  //private license: string | null = null
  private license = new ReplaySubject<string>(1);

  setLicense(license: string): void {
    if (!license || license.trim() === '') {
      console.error('Invalid license: License cannot be empty.');
      return;
    }
    this.license.next(license);
  }

  get license$(): Observable<string> {
    // Keine Prüfung auf leere License, da das ReplaySubject nur einen Wert ausgibt, wenn dieser explizit mit next() gesetzt wurde
    return this.license.asObservable();
  }
}
