import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ImageCropperComponent } from './image-cropper.component';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { AsyncPipe, CommonModule } from '@angular/common';

@NgModule({
  declarations: [ImageCropperComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [DesignSystemModule, AsyncPipe, CommonModule],
  exports: [ImageCropperComponent]
})
export class ImageCropperModule {}
